@tailwind base;
@tailwind components;
@tailwind utilities;

/* Transition styles for route changes */
.page-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  .page-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 300ms, transform 300ms;
  }
  .page-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .page-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
  }

  /* .glitter-effect {
    background-image: linear-gradient(
      90deg, 
      silver 0%, 
      silver 50%, 
      silver 100%
    );
    background-size: 200% auto;
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  
  .glitter-effect:hover {
    animation: shimmer 2s infinite linear;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -100%;
    }
    100% {
      background-position: 200%;
    }
  }
  
   */